<template>
    <div class="outsourcing-recruiting-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="outsourcing-recruiting__banner outsourcing-recruiting__banner--eclipse">
            <img
                class="outsourcing-recruiting__banner-img"
                src="@/assets/blog/outsourcing-recruiting/outsourcing-company-vs-recruiting-agency.jpg"
                alt="Hiring with Outsourcing Company vs Recruiting Agency"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="outsourcing-recruiting__banner-title">
                The Pros and Cons of Hiring with Outsourcing Web Development Company vs. Recruitment Agency
            </h1>
        </section>
        <div class="container outsourcing-recruiting section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        Whether you’re a professional Web Development company or a growing E-commerce business, when setting out to start a project, you are probably going to face the problem of selecting experienced staff to implement it. Many companies usually either contact a recruitment agency to find specialists for their team or consider partnering with an outsourcing web development company. Below we discuss the key differences between these two approaches, their pros and cons, to help you make the right decision that aligns with your expectations.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="outsourcing-recruiting__img">
                        <img
                            src="@/assets/blog/outsourcing-recruiting/article-image-1.jpg"
                            alt="Article image 1"
                            title="Article image 1"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Recruitment agencies and RPO companies
                    </h2>
                    <p>
                        When you work with Recruitment Agencies and RPO companies (recruitment process outsourcing), you rely on an external organization to cover some or all of your hiring processes. The difference between these two is that RPOs work with many clients and typically focus on long-term, ongoing partnerships, and Recruitment Agencies in most cases provide a one time support for immediate one time recruitment needs.
                    </p>
                    <p>
                        So, why you can choose this option for your business:
                    </p>
                    <p>
                        <b>Scalability.</b> Recruitment agencies can give your business the scalability it needs. They can adjust to the pace of growth that you desire. They have a ready talent pool that you can use now or in the future.
                    </p>
                    <p>
                        <b>Time efficiency.</b> You don’t need to spend time hiring a team. Everything is already at hand, and you don’t even need to manage them except for conveying your requirements to them. Still, in some cases, when you need a highly specialized developer for your project, you can get overloaded with bulk resumes not exactly matching your requirements.
                    </p>
                    <p>
                        <b>Cost efficiency.</b> Yes, it is significantly cheaper than managing an in-house hiring team. But you must bear in mind that if you partner with a recruitment agency you will need to pay service fees. Recruitment agencies are often paid per placement and in most cases request a fee based on the new hire’s starting salary. This means that working with agencies would be costlier when it comes to senior or very technical staff hires.
                    </p>
                    <p>
                        <b>Control over management.</b> With recruitment agencies, you have total control over whether you will select the candidates they suggest or not. At the same time, you don’t have to worry about all the work conditions, as recruitment companies do everything for you. Also, recruitment consultants are typically experts in their job and don’t need close supervision.
                    </p>
                    <p>
                        Yet, recruitment outsourcing companies work with many clients. This means you may sometimes have to vie for their attention.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="outsourcing-recruiting__img">
                        <img
                            src="@/assets/blog/outsourcing-recruiting/article-image-2.jpeg"
                            alt="Article image 2"
                            title="Article image 2"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Outsourcing Web Development
                    </h2>
                    <p>
                        Now that we are clear on the specifics of working with recruitment agencies, let’s take a look at outsourcing web development companies.
                    </p>
                    <p>
                        Outsourcing simply means employing a third party on a temporary or continuous basis to perform services or carry out project tasks. No administrative expenses, no training costs, no additional “service fees”.
                    </p>
                    <p>
                        How do you know this solution is right for your project? Here are some of the benefits of outsourcing to help make your future decision a bit easier:
                    </p>
                    <p>
                        <b>Cost efficiency.</b> The cost of hiring, training, and managing employees, as well as the infrastructure and equipment to keep a team productive, can quickly add up. Outsourcing is less expensive than hiring employees because you only pay for completed work rather than employee benefits, office space, and other overhead costs. All this saves budgets for business development.
                    </p>
                    <p>
                        At WDEVS, we use a flexible approach, as our prices vary from around $40/h. <a href="/contacts">Contact our team</a> to get the quote for a specific technology, specialist hire or scope of work.
                    </p>
                    <p>
                        <b>Productive collaboration and specialized market knowledge.</b> Outsourcing web dev companies can work together with your in-house development team and drive better results together. Also, when you reach them with a particular request for a highly-skilled developer for your project, you get exactly what you need because they work in the same industry as you or have a related experience with a wide portfolio of similar projects. Again, it won’t take time to find the right specialist for your tasks no matter how complex your request is.
                    </p>
                    <p>
                        Outsourcing development companies are made up of full-time professionals who have specialized degrees and certifications. More importantly, they’ve gained in-depth industry expertise from working with many different clients over time. They’re well-informed about the latest technologies and trends to get a strong understanding of your business due to their experience.
                    </p>
                    <p>
                        Finally, outsourcing web development can result in faster time-to-deployment. By outsourcing development work to a vendor, projects can be released faster than with your internal team only.
                    </p>
                    <div class="outsourcing-recruiting__img">
                        <img
                            src="@/assets/blog/outsourcing-recruiting/article-image-3.jpeg"
                            alt="Article image 2"
                            title="Article image 2"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <p>
                        <b>Flexibility and agile methodologies.</b> A lot of outsourced tasks can be carried out remotely, anywhere in the world. That means you have access to a much larger pool of candidates, compared to if you were looking to hire locally. So you can find not only the best one, but the most cost effective. Win-win!
                    </p>
                    <p>
                        Many of our developers are located in the US and Europe, so we are ready to work in the same time zone as you, delivering the expected results within the deadlines.
                    </p>
                    <p>
                        <b>Strategic partnership.</b> Outsourcing allows your business to better respond to difficulties and rapidly changing conditions, while saving you costs and providing a higher level of service. It is especially crucial at the time of growing recession, when many market players have either to skimp on quality or new opportunities by cutting the costs. Partnering with an outsourcing web development company gives you access to even more growth opportunities without blowing your budget.
                    </p>
                    <p>
                        Of course, despite the many benefits listed above, there are commonly known drawbacks of outsourcing you might have heard about already:
                    </p>
                    <p>
                        <i>
                            Because outsourcers are only used on a limited basis, and they have multiple clients, they are just not that engaged with your business.
                        </i>
                    </p>
                    <p>
                        Outsourcing with WDEVS promises the utmost care and attention to your brand’s values and business strategy. We use popular reporting and project management systems to track task progress and follow the benchmarks we agreed with you during our initial discussions. We provide consistent, high-quality support in an easy-to-monitor format.
                    </p>
                    <p>
                        <i>
                            Exchanging sensitive information can expose your company to security risks.
                        </i>
                    </p>
                    <p>
                        <a href="/">WDEVS</a> has a solid reputation proved by our clients’ success. We seriously address data security to prevent any data breach. We follow all mandatory and recommended security protocols to keep your information strictly confidential and safe. In fact, we are always ready to sign an NDA with your team to spell out precisely how we will use the information you entrust to us.
                    </p>
                    <p>
                        Moreover, we have been working with many of our clients under white label for years, developing exclusive ready-made products that can be quickly and easily implemented into the client’s existing business operations or be successfully resold or re-marketed to a new segment.
                    </p>
                    <p>
                        <i>
                            Outsourcing can lead to additional expenses due to poor management, lack of planning and unclear pricing policy.
                        </i>
                    </p>
                    <p>
                        At WDEVS, we follow a simple and clear Price-per-Hour rate system. The total cost is based on the number of hours developers worked, regardless of how many tasks they have completed during that time. The cost discussed with you will include all project introductory preparations and project management with no additional fees. For exact pricing, contact our team!
                    </p>
                    <p>
                        So, here you have it - a breakdown of all the pros and cons of partnering with a recruitment agency vs. specialized outsourcing web development company.
                    </p>
                    <p>
                        If outsourcing is an option you’re exploring, we would love to chat and walk you through any questions you might have about the various solutions WDEVS provides. Our full-service developers are on-demand and ready to help. <span class="link" @click="showModal=true">Get in touch now!</span>
                    </p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon"
                                ><img
                                    v-svg-inline
                                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                                    alt=""
                                /></span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalContact
            v-if="showModal"
            @close="showModal = false"
            :startProjectModal="false"
            :moreTextGetInTouch="modalWindowText"
        />
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";
import ModalContact from "/src/components/ModalContact.vue";

export default {
    name: "BlogMagentoCodeReview",
    data() {
        return {
            backHistory:history.state.back,
            showModal:false,
            tags:['Healthcare','Delivery','Calendar','Schedule','Delivery date'],
            scroll: false,
            position: null,
            text: `
              Would you like to receive more examples of what we are able to help you achieve?<br />
              Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
              Would you like us to propose ways of turning your ideas into reality?
            `,
            modalWindowText:'Want to know why we think we’re the best choice for all your IT requirements? Feel free to contact us directly and we’ll happily help you see the light!',
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'Hiring with Outsourcing Company vs Recruiting Agency',
                },
            ],
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
        ModalContact
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.outsourcing-recruiting-header {
    .outsourcing-recruiting-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 72px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .outsourcing-recruiting-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.outsourcing-recruiting-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.outsourcing-recruiting {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.outsourcing-recruiting__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .outsourcing-recruiting__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.outsourcing-recruiting__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 72px;
        max-width: 1150px;
    }
}
.outsourcing-recruiting__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.outsourcing-recruiting__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
